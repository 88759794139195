import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <section id="about" className="about-us">
            <h2>About The Style Hill</h2>
            <p>The Style Hill is a premier media marketing company that specializes in digital marketing, content creation, and branding. We help businesses grow their online presence and reach their target audience effectively.</p>
            <div className="about-content">
                <ul>
                    <li>Expertise in digital marketing strategies tailored to your business needs.</li>
                    <li>Professional content creation that engages and converts your audience.</li>
                    <li>Branding solutions that strengthen your brand's identity and visibility.</li>
                    <li>Data-driven approaches to ensure the highest ROI for your campaigns.</li>
                    <li>Comprehensive analytics and reporting to track your progress and success.</li>
                </ul>
                <p>Our team of experts is dedicated to helping you achieve your business goals. With The Style Hill, you can trust that your brand is in good hands.</p>
            </div>
        </section>
    );
}

export default AboutUs;
