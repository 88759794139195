import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Testimonials.css';

function Testimonials() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const testimonialsData = [
        {
            quote: "The Style Hill transformed our online presence. Their creative strategies and professional approach were beyond our expectations.",
            name: "John Doe",
            company: "CEO, ABC Corp"
        },
        {
            quote: "We've seen a significant boost in customer engagement since we started working with The Style Hill. Highly recommended!",
            name: "Jane Smith",
            company: "Marketing Director, XYZ Ltd"
        },
        {
            quote: "The team at The Style Hill is exceptional. Their insights and execution have been a game changer for our business.",
            name: "Michael Johnson",
            company: "Founder, StartUp Co."
        }
    ];

    return (
        <section className="testimonials">
            <h2>What Our Clients Say</h2>
            <Slider {...settings}>
                {testimonialsData.map((testimonial, index) => (
                    <div key={index} className="testimonial">
                        <p className="quote">"{testimonial.quote}"</p>
                        <p className="client-name">{testimonial.name}</p>
                        <p className="client-company">{testimonial.company}</p>
                    </div>
                ))}
            </Slider>
        </section>
    );
}

export default Testimonials;
