import React from 'react';
import logo from '../assets/Logo.png'; // Path to the uploaded logo
import './Footer.css';

function Footer() {
    return (
        <footer id="footer" className="footer">
            <div className="footer-content">
                <img src={logo} alt="The Style Hill Logo" className="footer-logo" />
                <p>&copy; 2024 The Style Hill. All Rights Reserved.</p>
                <p>Follow us on social media:</p>
                <ul className="social-links">
                    <li>
                        <a href="mailto:thestylehill@gmail.com" className="social-link facebook">Email Us</a>
                    </li>
                    <li>
                        <a href="https://wa.link/h5ssli" target="_blank" rel="noopener noreferrer" className="social-link twitter">WhatsApp</a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;
