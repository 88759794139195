import React from 'react';
import Header from './Header';
import Footer from './Footer';
import PackageCard from './PackageCard';
import './Services.css';

function Services() {
    const services = [
        {
            category: 'SEO Optimization',
            packages: [
                { title: 'Basic SEO', price: '$500', features: ['Keyword Research', 'On-page SEO', 'Basic Reporting'] },
                { title: 'Advanced SEO', price: '$1200', features: ['Basic SEO Features', 'Backlink Strategy', 'Monthly Reporting'] },
                { title: 'Enterprise SEO', price: '$2500', features: ['Advanced SEO Features', 'Competitor Analysis', 'Custom Strategy'] },
            ],
        },
        {
            category: 'Product Shoot',
            packages: [
                { title: 'Basic Shoot', price: '$300', features: ['5 Products', 'Basic Editing', 'High-Resolution Images'] },
                { title: 'Standard Shoot', price: '$800', features: ['10 Products', 'Advanced Editing', 'Creative Shots'] },
                { title: 'Premium Shoot', price: '$1500', features: ['20 Products', 'Professional Editing', 'On-site Shoot'] },
            ],
        },
        {
            category: 'Media Management',
            packages: [
                { title: 'Basic Management', price: '$400', features: ['Content Scheduling', 'Basic Analytics', 'Monthly Reporting'] },
                { title: 'Advanced Management', price: '$1000', features: ['Basic Features', 'Engagement Boosting', 'Weekly Reports'] },
                { title: 'Enterprise Management', price: '$2000', features: ['Advanced Features', 'Dedicated Account Manager', 'Real-time Reporting'] },
            ],
        },
        {
            category: 'Graphic Design',
            packages: [
                { title: 'Logo Design', price: '$200', features: ['2 Concepts', '3 Revisions', 'Final Files'] },
                { title: 'Brand Identity', price: '$800', features: ['Logo Design', 'Business Card', 'Brand Guidelines'] },
                { title: 'Complete Branding', price: '$1500', features: ['Brand Identity Package', 'Social Media Kit', 'Stationery Design'] },
            ],
        },
        {
            category: 'Content Creation',
            packages: [
                { title: 'Basic Content', price: '$300', features: ['2 Blog Posts', 'SEO Optimized', '500 Words Each'] },
                { title: 'Advanced Content', price: '$600', features: ['4 Blog Posts', 'SEO Optimized', '1000 Words Each'] },
                { title: 'Premium Content', price: '$1200', features: ['8 Blog Posts', 'SEO Optimized', '2000 Words Each'] },
            ],
        },
    ];

    return (
        <>
            <Header />
            <section className="services">
                <h2>Our Services</h2>
                {services.map((service, index) => (
                    <div key={index} className="service-category">
                        <h3>{service.category}</h3>
                        <div className="package-cards">
                            {service.packages.map((pkg, pkgIndex) => (
                                <PackageCard key={pkgIndex} title={pkg.title} price={pkg.price} features={pkg.features} />
                            ))}
                        </div>
                    </div>
                ))}
            </section>
            <Footer />
        </>
    );
}

export default Services;
