import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ImageSlider from '../components/Slider';
import TaglineSection from '../components/TaglineSection';
import AboutUs from '../components/AboutUs';
import Packages from '../components/Packages';
import Testimonials from '../components/Testimonials';

function Home() {
    return (
        <div>
            <Header />
            <TaglineSection />
            <ImageSlider />
            <AboutUs />
            <Packages />
            <Testimonials />
            <Footer />
        </div>
    );
}

export default Home;
