import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/Home';
import Services from './components/Services'; // Import the Services component

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route path="/services" element={<Services />} /> {/* Updated Route for Services page */}
            </Routes>
        </Router>
    );
}

export default App;
