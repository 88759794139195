import React from 'react';
import './PackageCard.css';

function PackageCard({ title, price, features }) {
    return (
        <div className="package-card">
            <h3>{title}</h3>
            <p>{price}</p>
            <ul>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </ul>
            <a href="mailto:thestylehill@gmail.com" className="contact-button">Contact Us</a> {/* Changed from button to anchor */}
        </div>
    );
}

export default PackageCard;
