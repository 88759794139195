import React from 'react';
import PackageCard from './PackageCard';
import './Packages.css';
function Packages() {
    const packages = [
        { title: 'Monthly Plan', price: '$300', features: ['Social Media Management', 'Content Creation', 'Monthly Reporting'] },
        { title: '6 Months Plan', price: '$1800', features: ['Includes Monthly Plan Features', 'SEO Optimization', 'Brand Strategy'] },
        { title: '12 Months Plan', price: '$2999', features: ['Includes 6 Months Plan Features', 'Ad Campaign Management', 'Comprehensive Analytics'] }
    ];

    return (
        <section id="packages" className="packages">
            <h2>Our Packages</h2>
            <div className="package-cards">
                {packages.map((pkg, index) => (
                    <PackageCard key={index} title={pkg.title} price={pkg.price} features={pkg.features} />
                ))}
            </div>
        </section>
    );
}

export default Packages;
