import React from 'react';
import './Slider.css';
import slider1 from '../assets/slider1.jpg'; 
import slider2 from '../assets/slider2.webp'; 
import slider3 from '../assets/slider3.png'; 

function ContentSections() {
    return (
        <div className="content-sections">
            <section className="section section1">
                <div className="content">
                    <h3>Engage Your Audience</h3>
                    <p>Targeted digital campaigns that reach the right people at the right time.</p>
                </div>
                <img src={slider1} alt="Digital Campaigns" />
            </section>
            <section className="section section2">
                <img src={slider2} alt="Marketing Strategy" />
                <div className="content">
                    <h3>Elevate Your Brand</h3>
                    <p>Innovative marketing solutions to take your business to the next level.</p>
                </div>
            </section>
            <section className="section section3">
                <div className="content">
                    <h3>Create Lasting Impressions</h3>
                    <p>Creative content that resonates with your audience and builds brand loyalty.</p>
                </div>
                <img src={slider3} alt="Creative Content" />
            </section>
        </div>
    );
}

export default ContentSections;
