import React from 'react';
import './TaglineSection.css';
import strategy from '../assets/strategy.png';

function TaglineSection() {
    return (
        <section className="tagline-section">
            <div className="tagline-content">
                <h2>Your Vision, Our Expertise</h2>
                <p>We provide personalized marketing solutions that fit your unique business needs.</p>
                <a href="mailto:thestylehill@gmail.com" className="quote-button">Get Personalized Quotation</a> {/* Changed from button to anchor */}
            </div>
            <div className="tagline-image">
                <img src={strategy} alt="Strategy" />
            </div>
        </section>
    );
}

export default TaglineSection;
