import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation to detect the current page
import logo from '../assets/Logo.png';
import './Header.css';

function Header() {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation(); // Get the current path

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    // Determine which buttons to show based on the current page
    const isHomePage = location.pathname === '/';
    const isServicesPage = location.pathname === '/services';

    return (
        <>
            <header className="header">
                <img src={logo} alt="The Style Hill Logo" className="logo" />
                <nav className={`nav-links ${isSidebarOpen ? 'hidden' : ''}`}>
                    <ul>
                        {isHomePage && (
                            <>
                                <li><Link to="/services" className="nav-button">Our Services</Link></li>
                                <li><a href="#packages" className="nav-button">Packages</a></li>
                                <li><a href="#about" className="nav-button">About Us</a></li>
                                <li><a href="#footer" className="nav-button">Contact Us</a></li>
                            </>
                        )}
                        {isServicesPage && (
                            <>
                                <li><Link to="/" className="nav-button">Home</Link></li>
                                <li><a href="/#about" className="nav-button">About Us</a></li>
                                <li><a href="/#footer" className="nav-button">Contact Us</a></li>
                            </>
                        )}
                    </ul>
                </nav>
                <button className="hamburger" onClick={toggleSidebar}>
                    &#9776; {/* Hamburger icon */}
                </button>
            </header>
            <div className={`sidebar-overlay ${isSidebarOpen ? 'visible' : ''}`} onClick={closeSidebar}></div>
            <nav className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <ul>
                    {isHomePage && (
                        <>
                            <li><Link to="/services" onClick={closeSidebar}>Our Services</Link></li>
                            <li><a href="#packages" onClick={closeSidebar}>Packages</a></li>
                            <li><a href="#about" onClick={closeSidebar}>About Us</a></li>
                            <li><a href="#footer" onClick={closeSidebar}>Contact Us</a></li>
                        </>
                    )}
                    {isServicesPage && (
                        <>
                            <li><Link to="/" onClick={closeSidebar}>Home</Link></li>
                            <li><a href="/#about" onClick={closeSidebar}>About Us</a></li>
                            <li><a href="/#footer" onClick={closeSidebar}>Contact Us</a></li>
                        </>
                    )}
                </ul>
            </nav>
        </>
    );
}

export default Header;
